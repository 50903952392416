import Head from 'next/head';
import {useRouter} from 'next/router';

// !STARTERCONF Change these default meta
const defaultMeta = {
    title: '境际之光',
    siteName: '广州境际之光科技有限公司',
    description:
        '广州境际之光科技有限公司，向主要企业基础技术储备快速部署，旅游景区数字化服务，自营商城小程序开发，宠物连锁自营平台开发，直播带货系统开发，企业软件外包服务，企业服务应用托管。',
    url: 'https://lateotu.com',
    type: 'website',
    robots: 'follow, index',
    keywords: '广州境际之光科技有限公司, 境际之光',
    image: 'https://lateotu.com/images/large-logo.png',
};

type SeoProps = {
    date?: string;
    templateTitle?: string;
} & Partial<typeof defaultMeta>;

export default function Seo(props: SeoProps) {
    const router = useRouter();
    const meta = {
        ...defaultMeta,
        ...props,
    };
    meta['title'] = props.templateTitle
        ? `${props.templateTitle}  -  ${meta.siteName}`
        : meta.title;

    // Use siteName if there is templateTitle
    // but show full title if there is none
    // !STARTERCONF Follow config for opengraph, by deploying one on https://github.com/theodorusclarence/og
    // ? Uncomment code below if you want to use default open graph
    // meta['image'] = openGraph({
    //     description: meta.description,
    //     siteName: props.templateTitle ? meta.siteName : meta.title,
    //     templateTitle: props.templateTitle,
    // });

    return (
        <Head>
            <title>{meta.title}</title>
            <meta name='robots' content={meta.robots}/>
            <meta content={meta.description} name='description'/>
            <meta property='og:url' content={`${meta.url}${router.asPath}`}/>
            <link rel='canonical' href={`${meta.url}${router.asPath}`}/>
            {/* Open Graph */}
            <meta property='og:type' content={meta.type}/>
            <meta property='og:site_name' content={meta.siteName}/>
            <meta property='og:description' content={meta.description}/>
            <meta property='og:title' content={meta.title}/>
            <meta name='image' property='og:image' content={meta.image}/>
            <meta name='keywords' property='og:keywords' content={meta.keywords}/>
            {/* Twitter */}
            <meta name='twitter:card' content='summary_large_image'/>
            {/* // !STARTERCONF Remove or change to your handle */}
            {/* <meta name='twitter:site' content='@th_clarence' /> */}
            <meta name='twitter:title' content={meta.title}/>
            <meta name='twitter:description' content={meta.description}/>
            <meta name='twitter:image' content={meta.image}/>
            {meta.date && (
                <>
                    <meta property='article:published_time' content={meta.date}/>
                    <meta
                        name='publish_date'
                        property='og:publish_date'
                        content={meta.date}
                    />
                    {/* // !STARTERCONF Remove or change to your name */}
                    <meta
                        name='author'
                        property='article:author'
                        content='黄运厚 ｜ 广州境际之光科技有限公司创始人'
                    />
                </>
            )}

            {/* Favicons */}
            {favicons.map((linkProps) => (
                <link key={linkProps.href} {...linkProps} />
            ))}
            <meta name='msapplication-TileColor' content='#ffffff'/>
            <meta name='msapplication-config' content='/favicon/browserconfig.xml'/>
            <meta name='theme-color' content='#ffffff'/>
        </Head>
    );
}

// !STARTERCONF this is the default favicon, you can generate your own from https://realfavicongenerator.net/
// ! then replace the whole /public/favicon folder and favicon.ico
const favicons: Array<React.ComponentPropsWithoutRef<'link'>> = [
    {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/favicon/apple-touch-icon.png',
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/favicon/favicon-32x32.png',
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/favicon/favicon-16x16.png',
    },
    {rel: 'manifest', href: '/favicon/site.webmanifest'},
    {
        rel: 'mask-icon',
        href: '/favicon/safari-mask-logo.svg',
        color: '#000',
    },
    {rel: 'shortcut icon', href: '/favicon/favicon.ico'},
];
